<template>
    <div
        v-if="linkBlocks && linkBlocks.length"
        class="link-blocks"
    >
        <template v-for="(linkBlock, key) in linkBlocks">
            <template v-if="linkBlock.linkSingle[0]">
                <NavLink
                    :key="key"
                    :type="mapType(linkBlock.linkSingle[0].__typename)"
                    :url="mapURI(linkBlock.linkSingle[0])"
                    :blank="linkBlock.linkSingle[0].openInNewWindow"
                    class="link-block"
                    :class="background"
                    data-ga-category="color-block-click"
                    :data-ga-action="mapURI(linkBlock.linkSingle[0])"
                    :data-ga-label="$route.path"
                    data-ga4-event="color_block_click"
                >
                    <ImageAsset
                        v-if="linkBlock.image[0]"
                        class="link-block__image"
                        :image="linkBlock.image[0]"
                        :max="980"
                        sizes="100vw"
                    />

                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        class="link-block__content article"
                        v-html="linkBlock.descriptionPlain"
                    />
                    <!-- eslint-enable vue/no-v-html -->

                    <div class="link-block__label button--label"><span>{{ linkBlock.linkSingle[0].header }}</span><BaseIcon icon="arrow-right" /></div>
                </NavLink>
            </template>
            <div
                v-else
                :key="key"
                class="link-block"
                :class="background"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="link-block__content article"
                    v-html="linkBlock.descriptionPlain"
                />
                <!-- eslint-enable vue/no-v-html -->
            </div>
        </template>
    </div>
</template>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        linkBlocks: {
            type: Array,
            required: true,
            default: () => { return []; }
        },
        background: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="less" src="./LinkBlocks.less"></style>
